body {
	height: 100%;
	background-color: #fff;
	font-family: 'Droid Sans', sans-serif;
	color: #727272;
	font-size: 1rem;
	line-height: 1.5rem;
}

h1 {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	padding-top: 0.5rem;
	background-image: url('/20170713122722/assets/images/bluegray_160x6.png');
	background-position: 1px 0px;
	background-size: 60px 2px;
	background-repeat: no-repeat;
	font-family: Rajdhani, sans-serif;
	color: #d32329;
	font-size: 2.25rem;
	line-height: 2.5rem;
	font-weight: 600;
}

h2 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	font-family: Rajdhani, sans-serif;
	color: #9e171c;
	font-size: 1.875rem;
	line-height: 2.25rem;
	font-weight: 600;
}

h3 {
	margin-top: 1rem;
	margin-bottom: 0.75rem;
	font-family: Rajdhani, sans-serif;
	color: #d32329;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 600;
}

h4 {
	margin-top: 1rem;
	margin-bottom: 0.75rem;
	font-family: Rajdhani, sans-serif;
	color: #9e171c;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 600;
}

h5 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	font-family: Rajdhani, sans-serif;
	color: #d32329;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
	text-transform: uppercase;
}

h6 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	font-family: Rajdhani, sans-serif;
	color: #9e171c;
	font-size: 0.875rem;
	line-height: 1.125rem;
	font-weight: 600;
	text-transform: uppercase;
}

p {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	color: #727272;
	line-height: 1.5rem;
}

a {
	color: #d32329;
	text-decoration: underline;
}

a:hover {
	color: #610609;
	text-decoration: none;
}

ul {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 20px;
}

li {
	margin-bottom: 0.5em;
}

img {
	display: inline-block;
	max-width: 100%;
}

label {
	display: block;
}

strong {
	font-weight: 700;
}

.button {
	display: inline-block;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	padding: 0.5rem 1.25rem;
	border-radius: 4px;
	background-color: #0c8563;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	font-family: Rajdhani, sans-serif;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}

.button:hover {
	background-color: #0b4b86;
	color: #fff;
}

.button.secondary-button {
	margin-left: 1rem;
	background-color: #475461;
}

.button.secondary-button:hover {
	background-color: #2c353d;
}

.button.tertiary-button {
	margin-left: 1rem;
	background-color: #d4dae0;
	color: #475461;
}

.button.tertiary-button:hover {
	background-color: #caccce;
}

.button.mobile-search-button {
	overflow: hidden;
	width: 20%;
	height: 3rem;
	margin-top: 0em;
	margin-bottom: 0em;
	padding-right: 0em;
	padding-left: 0em;
	float: right;
	background-image: url('/20170713122722/assets/images/search_wht.svg');
	background-position: 50% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	color: transparent;
	line-height: 1.5em;
}

.button.mobile-search-button:hover {
	background-color: rgba(37, 50, 66, 0.5);
}

.button.slider-cta {
	margin-top: 0.125em;
	margin-bottom: 0.25em;
	padding-bottom: 0.375em;
	background-color: #0c8563;
	text-align: left;
}

.button.slider-cta:hover {
	background-color: #0b4b86;
}

.list {
	margin-top: 0.25rem;
	margin-bottom: 0.75rem;
	padding-left: 20px;
}

.link {
	display: inline-block;
	color: #1b70ba;
}

.link:hover {
	color: #0c8563;
}

.link.inside-right-column-link {
	padding-right: 0.125em;
	padding-left: 0.125em;
}

.link.footer-list-link {
	display: block;
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: 12px;
	border-bottom-style: none;
	background-image: url('/20170713122722/assets/images/Arrow4_right_blk_25.svg');
	background-position: 0px 50%;
	background-size: 5px 9px;
	background-repeat: no-repeat;
	color: #d32329;
	font-size: 0.9375rem;
	text-decoration: none;
}

.link.footer-list-link:hover {
	color: #610609;
	text-decoration: none;
}

.link.breadcrumb-link {
	border-bottom: 0px solid transparent;
	color: #606060;
	text-decoration: underline;
}

.link.breadcrumb-link:hover {
	color: #d32329;
	text-decoration: none;
}

.link.content-box-title-link {
	border-bottom-style: none;
	color: #0c8563;
}

.link.content-box-title-link:hover {
	color: #257ecb;
}

.link.home-event-title-link {
	border-bottom-style: none;
}

.link.home-event-title-link:hover {
	color: #0c8563;
}

.nav-section {
	border-top: 1px solid #fff;
	background-color: transparent;
	background-image: linear-gradient(113deg, #d32329 45%, hsla(0, 0%, 100%, 0.8) 72%);
	background-position: 0px 0px;
}

.nav-logo-block {
	margin-bottom: 0.5rem;
	-webkit-transition: all 200ms ease, background-color 200ms ease, color 200ms ease, border 200ms ease, opacity 200ms ease, box-shadow 200ms ease;
	transition: all 200ms ease, background-color 200ms ease, color 200ms ease, border 200ms ease, opacity 200ms ease, box-shadow 200ms ease;
	text-decoration: none;
	z-index: 1100;
	background-color: transparent;
	width: auto;
}

.nav-menu {
	display: inline-block;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	float: left;
	box-shadow: -1px 0 0 0 hsla(0, 0%, 100%, 0.07);
}

.nav-menu.new-nav-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: left;
}

.nav-link {
	margin-right: 1px;
	padding: 1rem 3rem;
	float: left;
	box-shadow: 1px 0 0 0 hsla(0, 0%, 100%, 0.25);
	-webkit-transition: color 100ms ease, background-color 100ms ease;
	transition: color 100ms ease, background-color 100ms ease;
	font-family: Rajdhani, sans-serif;
	color: #fff;
	font-size: 1.25rem;
	font-weight: 600;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.nav-link:hover {
	background-color: rgba(52, 60, 71, 0.55);
	color: #fff;
}

.nav-link.w--current {
	background: rgba(57, 68, 78, 0.35);
	color: #fff;
}

.nav-link.dropdown-toggle {
	text-align: center;
}

.nav-link.dropdown-toggle.two-part-button-toggle {
	padding-right: 1rem;
}

.nav-link.nav-two-part-button-link {
	width: 100%;
	padding-right: 3rem;
	padding-left: 2.25rem;
	background-image: url('/20170713122722/assets/images/icon_arrow2_down_blk_25_extended.svg');
	background-position: 100% 50%;
	background-size: 52px 6px;
	background-repeat: no-repeat;
	text-align: center;
	cursor: pointer;
}

.nav-link.nav-two-part-button-link.last-link {
	box-shadow: 1px 0 0 0 hsla(0, 0%, 100%, 0);
}

.nav-link.new-nav-link {
	position: relative;
	display: block;
	width: 100%;
	margin-right: 0px;
	padding-right: 3rem;
	padding-left: 1.5rem;
	float: none;
	box-shadow: none;
	text-align: center;
}

.nav-link.new-nav-link:hover {
	background-color: transparent;
}

.nav-link.new-nodrop-link {
	padding-right: 2.5rem;
	padding-left: 2.5rem;
	text-align: center;
}

.nav-dropdown {
	width: 18%;
	margin-right: 1px;
	float: left;
}

.nav-dropdown.two-part-button {
	float: right;
}

.nav-dropdown.about-us {
	width: 18%;
}

.nav-dropdown.events {
	width: 15.5%;
}

.nav-dropdown.login {
	width: 13.95%;
	margin-right: 0px;
}

.nav-dropdown.resources {
	width: 19.5%;
}

.nav-dropdown-icon {
	width: 1rem;
	height: 1rem;
}

.nav-logo-image {
	width: 300px;
	margin-left: 1px;
	opacity: 1;
}

.nav-dropdown-list {
	top: 100%;
	max-width: 230px;
	min-width: 100%;
	background-color: rgba(238, 245, 252, 0.9);
	box-shadow: inset 1px 0 0 0 rgba(140, 164, 189, 0.25), inset 0 -1px 0 0 rgba(140, 164, 189, 0.25), inset -1px 0 0 0 rgba(140, 164, 189, 0.25);
	-webkit-transition: all 100ms ease;
	transition: all 100ms ease;
}

.nav-dropdown-list.w--open {
	max-width: none;
}

.nav-dropdown-link {
	overflow: visible;
	margin-top: 1px;
	padding: 0.625rem 0.625rem 0.5rem;
	box-shadow: 0 -1px 0 0 rgba(118, 148, 179, 0.35);
	font-family: Rajdhani, sans-serif;
	color: #0b4b86;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 600;
	letter-spacing: 0.03em;
	text-decoration: none;
}

.nav-dropdown-link:hover {
	background-color: #0b4b86;
	color: #fff;
}

.nav-dropdown-link.w--current {
	background: #0b4b86;
	color: #fff;
}

.nav-dropdown-link.w--current:hover {
	background-color: #0b4b86;
	color: #fff;
}

.nav-dropdown-link.mega-menu-column-title-link {
	background-color: rgba(37, 50, 66, 0.5);
	box-shadow: 0 -2px 0 0 #25cb72;
	-webkit-transition: all 200ms ease, background-color 200ms ease, color 200ms ease, border 200ms ease, opacity 200ms ease, box-shadow 200ms ease;
	transition: all 200ms ease, background-color 200ms ease, color 200ms ease, border 200ms ease, opacity 200ms ease, box-shadow 200ms ease;
	color: #fff;
}

.nav-dropdown-link.mega-menu-column-title-link:hover {
	background-color: #1b8354;
	color: #fff;
}

.nav-dropdown-link.mega-menu-column-title-link.w--current {
	background-color: #2474b3;
	background-position: 0px 0px, 0px 0px;
}

.nav-dropdown-link.mega-menu-column-title-link.w--current:hover {
	background-color: #24b273;
	background-position: 0px 0px, 0px 0px;
}

.nav-dropdown-link.mega-menu-link {
	padding-right: 1.5rem;
	background-image: url('/20170713122722/assets/images/icon_arrow2_right_ISU_blue.svg');
	background-position: 95% 50%;
	background-size: 8px 8px;
	background-repeat: no-repeat;
}

.nav-dropdown-link.mega-menu-link:hover {
	background-image: url('/20170713122722/assets/images/icon_arrow2_right_wht.svg');
}

.nav-dropdown-link.mega-menu-link.w--current {
	background-image: url('/20170713122722/assets/images/icon_arrow2_right_wht.svg');
}

.nav-dropdown-link.mega-menu-link.new-mega-menu-link {
	position: static;
	background-image: none;
}

.nav-dropdown-link.mega-menu-link.new-mega-menu-link:hover {
	background-color: #0b4b86;
}

.nav-two-part-button {
	float: none;
}

.two-part-button-arrow {
	width: 1.5rem;
	height: 1.5rem;
	min-height: 0px;
	min-width: 0px;
	float: right;
	background-image: url('/20170713122722/assets/images/icon_arrow2_right_wht.svg');
	background-position: 50% 50%;
	background-size: 1rem 1rem;
	background-repeat: no-repeat;
}

.form-wrapper {
	margin-bottom: 3rem;
}

.form-field-label {
	margin-top: 1em;
	margin-bottom: 0.25em;
}

.form-checkbox-field {
	margin-bottom: 0.25rem;
}

.form-checkbox {
	width: 1rem;
	height: 1rem;
	margin-top: 0.25rem;
}

.form-session-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem;
	background-color: #000;
	color: #fff;
}

.form-session-message.success {
	background: #6bb35f;
}

.form-session-message.error {
	background: #d64949;
}

.form-radio-button-field {
	margin-bottom: 0.25rem;
	padding-left: 1.75em;
}

.form-radio-button {
	width: 1.25em;
	height: 1.25em;
	margin-top: 0.25rem;
	margin-left: -1.75em;
}

.form-session-message-title {
	display: inline-block;
	margin-bottom: 1rem;
	font-size: 1.25rem;
	font-weight: 700;
}

.form-session-message-icon {
	width: 24px;
	height: 24px;
	margin-right: 10px;
	padding-right: 2px;
	padding-bottom: 6px;
	padding-left: 2px;
}

.slider {
	position: relative;
	display: block;
	height: 100%;
	margin-top: 0px;
	margin-right: auto;
	margin-left: auto;
	background-color: transparent;
	box-shadow: 1px 0 0 0 rgba(193, 213, 233, 0.25), -1px 0 0 0 rgba(193, 213, 233, 0.25);
}

.slider-mask {
	height: 100%;
}

.slider-arrow {
	left: auto;
	top: auto;
	right: 101px;
	bottom: 0px;
	max-height: 50px;
	max-width: 50px;
	min-height: 50px;
	background-color: rgba(215, 55, 61, 0.65);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: hsla(0, 0%, 100%, 0.75);
}

.slider-arrow:hover {
	background-color: rgba(94, 15, 15, 0.47);
	color: #fff;
}

.slider-arrow.right-arrow {
	right: 50px;
}

.slider-indicators {
	bottom: 0rem;
	display: none;
	height: 51px;
	padding-top: 20px;
	padding-right: 70px;
	padding-left: 70px;
	opacity: 0.75;
	font-size: 0.5rem;
}

.slider-slide {
	position: relative;
	height: 100%;
}

.section.footer-section {
	background-color: #f7faff;
	background-image: none;
	box-shadow: none;
	color: #677685;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.section.off-white-section {
	border-top: 3px solid #d32329;
	background-color: #f7faff;
}

.section.off-white-section.inside-page-section {
	position: relative;
	padding-top: 3rem;
	border-top-style: none;
}

.section.slider-section {
	height: 65vh;
	background-color: #ddd;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.column.zeroed-column {
	padding-right: 0px;
	padding-bottom: 2.5rem;
	padding-left: 0px;
}

.column.signoff-column {
	padding-right: 0px;
	padding-left: 0px;
}

.divider {
	height: 2px;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	background-color: hsla(0, 0%, 45%, 0.11);
}

.breadcrumbs-list {
	overflow: hidden;
	margin-top: -3rem;
	margin-bottom: 2.5rem;
	padding-right: 0.125rem;
	padding-left: 0.125rem;
	font-family: Rajdhani, sans-serif;
	color: #a4b4c4;
	font-size: 0.8125rem;
	line-height: 1em;
	font-weight: 600;
	text-transform: uppercase;
}

.breadcrumbs-list-item {
	display: inline-block;
	margin-top: 0.125em;
	margin-right: 0.75em;
	margin-bottom: 0.125em;
	padding-top: 0.125rem;
}

.breadcrumbs-list-divider {
	display: inline-block;
	margin-left: 0.75em;
	color: #606060;
}

.split-50 {
	width: 50%;
	min-width: 0px;
	float: left;
}

.inside-page-content-column {
	padding-right: 10px;
	padding-left: 10px;
}

.left-nav-list {
	margin-top: 0px;
	margin-bottom: 1rem;
	clear: both;
	box-shadow: 0 1px 0 0 rgba(140, 164, 189, 0.35);
}

.left-nav-list.nested-left-nav-list {
	width: 100%;
	margin-bottom: 1px;
	padding: 0.375rem 0.5rem 1rem;
	float: left;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(33, 35, 37, 0.05)), to(rgba(33, 35, 37, 0.05)));
	background-image: linear-gradient(180deg, rgba(33, 35, 37, 0.05), rgba(33, 35, 37, 0.05));
	box-shadow: 0 1px 0 0 #fff, inset 0 1px 0 0 rgba(37, 126, 203, 0.25);
	color: #727272;
}

.left-nav-list-item {
	margin-top: 1px;
	margin-bottom: 0px;
	box-shadow: 0 -1px 0 0 rgba(140, 164, 189, 0.35);
}

.left-nav-list-item.nested-left-nav-list-item {
	margin-top: 0px;
	box-shadow: none;
}

.left-nav-list-item-link {
	display: block;
	width: 100%;
	margin-top: 1px;
	margin-bottom: 1px;
	padding: 0.625rem 0.5rem 0.5rem;
	float: left;
	clear: both;
	font-family: Rajdhani, sans-serif;
	color: #414141;
	font-size: 1.125rem;
	line-height: 1.125rem;
	font-weight: 600;
	text-decoration: none;
}

.left-nav-list-item-link:hover {
	background-color: #d32329;
	color: #fff;
}

.left-nav-list-item-link.w--current {
	background-color: #d32329;
	color: #fff;
}

.left-nav-list-item-link.w--current:hover {
	background-color: #d32329;
	color: #fff;
}

.left-nav-list-item-link.nested-left-nav-link {
	padding-top: 0.5rem;
	padding-bottom: 0.375rem;
	padding-left: 0.875rem;
	background-image: url('/20170713122722/assets/images/Arrow4_right_blk_25.svg');
	background-position: 3px 11px;
	background-size: 5px 9px;
	background-repeat: no-repeat;
	color: rgba(65, 65, 65, 0.76);
	font-size: 0.9375rem;
	line-height: 1rem;
	letter-spacing: 0.02em;
}

.left-nav-list-item-link.nested-left-nav-link:hover {
	background-size: 5px 9px;
	color: #fff;
}

.left-nav-list-item-link.nested-left-nav-link.w--current {
	background-color: transparent;
	background-image: url('/20170713122722/assets/images/Arrow4_right_blk_50.svg');
	background-size: 5px 9px;
	color: #0c8563;
}

.left-nav-list-item-link.nested-left-nav-link.w--current:hover {
	background-color: #0c8563;
	background-position: 3px 11px;
	color: #fff;
}

.left-nav-list-item-link.left-nav-title-link,
.left-nav-list-item-link.left-nav-title-link.w--current {
	margin-top: 0px;
	margin-bottom: 2px;
	padding-top: 1.375rem;
	padding-bottom: 1.125rem;
	padding-left: 0rem;
	background-image: url('/20170713122722/assets/images/bluegray_160x6.png');
	background-position: 0px 0px;
	background-size: 40px 2px;
	background-repeat: no-repeat;
	color: #9e171c;
	font-size: 1.625rem;
	line-height: 1em;
	text-transform: uppercase;
	background-color: transparent;
}

.left-nav-list-item-link.left-nav-title-link:hover,
.left-nav-list-item-link.left-nav-title-link.w--current:hover {
	background-color: transparent;
	background-image: url('/20170713122722/assets/images/bluegray_160x6.png');
	color: #610609;
}


.inside-page-nested-content-column {
	padding-right: 10px;
	padding-left: 10px;
}

.inside-page-right-column-heading {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	color: #0c8563;
	font-size: 1.5em;
	line-height: 1em;
}

.inside-page-sidebar-column-section-wrap {
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
	margin-left: 15px;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-image: url('/20170713122722/assets/images/bluegray_160x6.png');
	background-position: 0px 0px;
	background-size: 20px 2px;
	background-repeat: no-repeat;
	font-size: 0.875rem;
	line-height: 1.375em;
}

.slider-contents-wrapper {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
	max-width: 1170px;
	margin-right: auto;
	margin-left: auto;
	font-family: Rajdhani, sans-serif;
	font-weight: 500;
}

.content-box-wrap {
	margin: 10px;
	background-color: transparent;
}

.content-box-image-link {
	display: block;
	height: 160px;
	border-top: 2px solid #d32329;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.content-box-image-link.image-1 {
	background-size: cover;
}

.content-box-image-link.image-2 {
	background-size: cover;
}

.content-box-text {
	color: #5c5c5c;
	font-family: inherit;
}

.column-content-wrap {
	margin-right: 10px;
	margin-left: 10px;
}

.footer-signoff-text {
	margin-top: 1rem;
	float: right;
	color: #5c5c5c;
	font-size: 0.75rem;
	text-align: left;
}

.footer-signoff-text h1 {
	font-family: 'Droid Sans', sans-serif;
	display: inline;
	line-height: 1.25rem;
	font-weight: 400;
	margin: 0;
	padding: 0;
	text-transform: none;
	color: #5c5c5c;
	font-size: 0.75rem;
	background-image: none;
}

.content-box-text-wrap {
	padding: 0rem 0.75rem 0rem 0rem;
}

.footer-list {
	margin-top: 0rem;
	margin-right: 10px;
	margin-left: 10px;
}

.footer-list-item {
	margin-bottom: 0em;
	border-bottom: 1px solid rgba(148, 164, 180, 0.35);
}

.footer-social-icons-wrap {
	padding-top: 0.125rem;
	display: inline-block;
}

.footer-social-icon-link {
	width: 1.5rem;
	height: 1.5rem;
	min-width: 0px;
	margin-top: 0.125rem;
	margin-right: 0.25rem;
	margin-bottom: 0.125rem;
}

.footer-social-icon-link img {
	transition: box-shadow 0.3s ease-in-out;
}

.footer-social-icon-link:hover img {
	box-shadow: 0px 0px 6px 0px #363636;
}

.footer-social-icon-image {
	float: left;
}

.inside-page-right-column-list {
	margin-top: 0rem;
}

.inside-page-right-column-list-item {
	margin-bottom: 0.25em;
	padding-top: 0.15rem;
	padding-bottom: 0.15rem;
}

.inside-page-intro {
	color: #626f7c;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.header-container {
	position: relative;
	display: block;
	padding-left: 0em;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	max-width: none;
}

.form-field {
	min-height: 2.5rem;
	margin-bottom: 0.25rem;
	padding: 0.5rem 0.75rem;
	border: 1px solid #d6d6d6;
	border-radius: 0px;
}

.footer-column-title {
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-top: 0.25rem;
	padding-bottom: 0.75rem;
	color: #d32329;
	font-size: 1.25rem;
	line-height: 1.38rem;
	text-transform: uppercase;
}

.left-nav-wrap {
	padding-top: 0.25rem;
	padding-right: 20px;
	padding-bottom: 2rem;
	border-right: 1px solid rgba(140, 164, 189, 0.35);
}

.slider-arrow-icon {
	top: auto;
	bottom: 0px;
	max-height: 50px;
	max-width: 50px;
	min-height: 50px;
	min-width: 50px;
	font-size: 1.75rem;
	line-height: 50px;
}

.nav-menu-wrap {
	position: relative;
	clear: both;
	border-top: 1px solid hsla(0, 0%, 100%, 0.15);
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.15);
	background-color: #d32329;
	background-image: none;
	background-position: 0px 0px;
}

.header-content-wrap {
	position: static;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	text-align: center;
}

.header-content-right-wrap {
	padding-top: 0.375rem;
	padding-right: 0.875rem;
	float: right;
}

.header-phone-wrap {
	float: left;
	font-family: Rajdhani, sans-serif;
	color: #fff;
	font-size: 1.625rem;
	line-height: 2.25rem;
	font-weight: 600;
}

.header-phone-wrap.mobile {
	display: none;
}

.header-phone-icon {
	width: 2.25rem;
	height: 2.25rem;
	margin-right: 0.25rem;
	padding: 6px;
	float: left;
	opacity: 0.35;
}

.header-phone-text {
	float: left;
	color: #d32329;
}

.secondary-header-link-wrap {
	margin-left: 1.5rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	float: left;
	font-family: Rajdhani, sans-serif;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1rem;
	font-weight: 600;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.secondary-header-link-text {
	float: left;
	color: #d32329;
}

.secondary-header-link-text.cart-item-label {
	display: inline-block;
	margin-left: 0.25rem;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	border-radius: 3px;
	background-color: rgba(0, 0, 0, 0.35);
	font-size: 0.625rem;
	font-weight: 700;
	letter-spacing: 1px;
}

.secondary-header-link-icon {
	width: 1rem;
	height: 1.25rem;
	margin-top: -0.125rem;
	margin-right: 0.375rem;
	float: left;
	opacity: 0.4;
}

.secondary-header-link-list-wrap {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	float: right;
	clear: both;
	box-shadow: inset 0 3px 0 0 transparent;
}

.footer-top-border {
	display: block;
	height: 1rem;
	max-width: 1170px;
	margin-right: auto;
	margin-left: auto;
	background-color: #d32329;
	background-image: none;
	background-position: 0px 0px;
	box-shadow: -1px 0 0 0 rgba(193, 213, 233, 0.25), 1px 0 0 0 rgba(193, 213, 233, 0.25);
	color: #fff;
	text-align: center;
}

.home-solutions-list {
	border-bottom: 1px solid rgba(111, 124, 136, 0.15);
}

.nav-container {
	position: relative;
	max-width: 1170px;
	box-shadow: 1px 0 0 0 hsla(0, 0%, 100%, 0.25), -1px 0 0 0 hsla(0, 0%, 100%, 0.2);
	text-align: center;
}

.nav-container.new-nav-container {
	box-shadow: -1px 0 0 0 hsla(0, 0%, 100%, 0.2);
}

.mega-menu-dropdown-wrap {
	position: static;
	display: block;
	width: 100%;
}

.mega-menu-dropdown-list {
	left: 100%;
	top: 0px;
	overflow: hidden;
	width: 432.5%;
	padding: 0.75rem 0.5rem 1.25rem;
	border-top: 2px solid #09b988;
	background-color: rgba(238, 245, 252, 0.93);
	box-shadow: inset 1px 0 0 0 rgba(140, 164, 189, 0.25), inset -1px 0 0 0 rgba(140, 164, 189, 0.25), inset 0 -1px 0 0 rgba(140, 164, 189, 0.25);
	-webkit-transition: all 100ms ease;
	transition: all 100ms ease;
}

.mega-menu-column {
	width: 24%;
	margin-right: 0.5%;
	margin-left: 0.5%;
	float: left;
}

.mega-menu-category-wrap {
	margin-bottom: 1.0625rem;
	border-bottom: 1px solid rgba(118, 148, 179, 0.35);
}

.home-solutions-list-item-link {
	display: block;
	padding-top: 0.75rem;
	padding-bottom: 0.625rem;
	border-bottom: 0px none transparent;
	background-image: url('/20170713122722/assets/images/icon_arrow3_right_cblgray.svg');
	background-position: 97% 50%;
	background-size: 10px 10px;
	background-repeat: no-repeat;
	font-family: Rajdhani, sans-serif;
	font-size: 1.125rem;
	line-height: 1.125rem;
	font-weight: 600;
}

.home-solutions-list-item-link:hover {
	color: #0c8563;
}

.home-solutions-list-text {
	margin-left: 0.375rem;
	float: left;
	color: #1b70ba;
}

.home-solutions-list-text:hover {
	color: #0c8563;
}

.home-solutions-list-item {
	margin-bottom: 0em;
	border-top: 1px solid rgba(111, 124, 136, 0.15);
}

.slider-slide-image-block {
	position: relative;
	height: 100%;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.slider-overlay-title {
	margin-top: 0rem;
	margin-bottom: 0.125rem;
	padding-top: 0rem;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	color: #fff;
	font-size: 2.5rem;
	line-height: 2.75rem;
	text-transform: uppercase;
}

.wide-wrapper {
	display: block;
	max-width: 1170px;
	margin-right: auto;
	margin-left: auto;
}

.wide-wrapper.white-wrapper {
	padding: 1.5rem 20px 1.75rem;
	background-color: #fff;
	box-shadow: 1px 0 0 0 rgba(111, 124, 136, 0.15), -1px 0 0 0 rgba(111, 124, 136, 0.15);
}

.wide-wrapper.white-wrapper.inside-page-wrapper {
	position: relative;
	min-height: 400px;
	padding-top: 1rem;
	padding-bottom: 2rem;
	box-shadow: 1px 0 0 0 rgba(111, 124, 136, 0.15), -1px 0 0 0 rgba(111, 124, 136, 0.15), 0 -1px 0 0 rgba(111, 124, 136, 0.15);
	text-align: left;
}

.wide-wrapper.light-overlay-wrapper {
	padding-top: 2rem;
	padding-bottom: 2rem;
	background-color: rgba(255, 255, 255, 0.85);
	background-image: none;
	box-shadow: 1px 0 0 0 rgba(148, 164, 180, 0.2), -1px 0 0 0 rgba(148, 164, 180, 0.2);
}

.wide-wrapper.light-overlay-wrapper.inside-page-header-overlay {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.wide-wrapper.light-overlay-wrapper.footer-signoff {
	padding-top: 0rem;
	padding-bottom: 3rem;
	border-top: 1px solid rgba(148, 164, 180, 0.35);
	box-shadow: 1px 0 0 0 rgba(148, 164, 180, 0.2), -1px 0 0 0 rgba(148, 164, 180, 0.2);
}

.wide-wrapper.light-overlay-wrapper.footer-wrap {
	padding-bottom: 0rem;
}

.clients-slider {
	position: relative;
	height: 80px;
	margin-bottom: 0.5rem;
	border: 1px none rgba(111, 124, 136, 0.15);
	background-color: transparent;
}

.clients-slider-arrow {
	z-index: 21;
	height: 100%;
	max-width: 2rem;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	color: #25cb72;
	font-size: 1.25rem;
}

.clients-slider-arrow:hover {
	background-color: rgba(37, 50, 66, 0.5);
	color: #fff;
}

.clients-slider-slide {
	position: relative;
	width: 20%;
	height: 60px;
	min-width: 100px;
	margin-top: 10px;
	border-right: 1px solid rgba(111, 124, 136, 0.07);
}

.clients-slider-slide.slide-1 {
	border-left: 1px solid rgba(111, 124, 136, 0.07);
}

.clients-slider-mask {
	position: relative;
	height: 100%;
	padding-right: 2rem;
	padding-left: 2rem;
}

.clients-slider-image {
	position: relative;
	top: 50%;
	display: block;
	max-height: 50px;
	max-width: 100px;
	margin-right: auto;
	margin-left: auto;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
}

.home-event-wrap {
	margin-bottom: 0.75rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid rgba(111, 124, 136, 0.15);
}

.home-event-date {
	display: inline-block;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	background-color: #0c8563;
	color: #fff;
	font-size: 0.625rem;
	line-height: 1rem;
	font-weight: 700;
	text-transform: uppercase;
}

.home-event-title {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	padding-top: 0.125rem;
	font-family: Rajdhani, sans-serif;
	font-size: 1.25rem;
	line-height: 1.25rem;
	font-weight: 600;
}

.home-event-description {
	margin-bottom: 0.5rem;
	color: #727272;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.content-box-view-more-block {
	display: inline-block;
	margin-top: 0.5rem;
	margin-right: 1.5rem;
	padding: 0.25rem 1.5rem 0.125rem 0.125rem;
	clear: both;
	border-top: 1px solid rgba(148, 164, 180, 0.25);
	background-image: url('/20170713122722/assets/images/icon_arrow3_right_cblgray.svg');
	background-position: 98% 52%;
	background-size: 12px 10px;
	background-repeat: no-repeat;
	font-family: Rajdhani, sans-serif;
	color: #d32329;
	font-size: 1rem;
	font-weight: 600;
	text-transform: uppercase;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.content-box-view-more-block:hover {
	color: #9e171c;
	text-decoration: none !important;
	background-image: url('/20170713122722/assets/images/icon_arrow3_right_cblgray.svg');
}

.content-box-view-more-block.row-2-learn-more {
	position: absolute;
	left: 0px;
	border-bottom: 1px solid transparent;
	margin-left: 10px;
	border-top: 1px solid rgba(148, 164, 180, 0.25);
	text-decoration: none;
	color: #d32329;
}

.content-box-view-more-block.row-2-learn-more:hover {
	color: #9e171c;
	text-decoration: none !important;
	background-image: url('/20170713122722/assets/images/icon_arrow3_right_cblgray.svg');
}

.content-box-title {
	margin-top: 0.5rem;
	margin-bottom: 0.44em;
	padding-top: 0.5rem;
	padding-bottom: 0rem;
	clear: both;
	background-image: url('/20170713122722/assets/images/bluegray_160x6.png');
	background-position: 1px 0px;
	background-size: 60px 2px;
	background-repeat: no-repeat;
	color: #0c8563;
	font-size: 2rem;
	line-height: 2.25rem;
}

.content-box-title.clients-slider-title {
	display: inline-block;
	min-width: 33%;
	background-position: 50% 0px;
}

.home-solutions-content-wrap {
	padding-bottom: 0.5rem;
}

.clients-slider-cbox-wrap {
	margin-bottom: 1rem;
	text-align: center;
}

.home-column-right {
	width: 66%;
	padding-bottom: 2rem;
	padding-left: 1rem;
	float: right;
}

.home-column-left {
	width: 33%;
	padding-right: 1.75rem;
	float: left;
	border-right: 1px solid rgba(111, 124, 136, 0.15);
}

.navbar-wrap {
	position: relative;
	left: 0px;
	top: 0px;
	right: 0px;
	z-index: 10000;
	width: 100%;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.content-box-alt-color-row {
	display: block;
	margin-right: auto;
	margin-left: auto;
	background-color: #f7faff;
}

.content-box-image-row-1 {
	display: block;
	width: 100%;
}

.content-box-image-row-1-link {
	display: none;
	width: 100%;
	margin-top: 0.5em;
}

.nav-logo-image-wrap {
	display: block;
	width: 100%;
	padding: 0.75rem 0.75rem 0.5rem;
	background-color: transparent;
}

.nav-logo-curve {
	display: block;
	width: 100%;
	height: 20px;
	margin-top: -2px;
	background-image: url('/20170713122722/assets/images/logo_curve_bottom.svg');
	background-position: 50% 100%;
	background-size: 100% 115%;
	background-repeat: no-repeat;
}

.slider-contents-inner-wrapper {
	position: absolute;
	left: 20px;
	right: 20px;
	bottom: 0px;
	display: inline-block;
	max-width: 980px;
	margin-right: auto;
	margin-left: auto;
}

.desktop-search-button {
	width: 2.25rem;
	height: 2.25rem;
	float: right;
	border-radius: 4px;
	background-image: url('/20170713122722/assets/images/search_blk.svg');
	background-position: 50% 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	opacity: 0.35;
	cursor: pointer;
	background-color: transparent;
}

.desktop-search-button:hover {
	opacity: 0.65;
}

input[type="text"].header-search-input {
	height: 2.25rem;
	max-width: 100px;
	min-width: 100px;
	margin-bottom: 0px;
	padding: 4px 4px 4px 10px;
	float: left;
	border: 1px none rgba(111, 124, 136, 0.15);
	border-radius: 4px;
	-webkit-transition: width 500ms ease, max-width 500ms ease, min-width 500ms ease;
	transition: width 500ms ease, max-width 500ms ease, min-width 500ms ease;
	font-size: 0.75rem;
}

input[type="text"].header-search-input:focus {
	max-width: 150px;
	min-width: 150px;
	border-style: none;
}

.header-search-wrap {
	margin-left: 2.25rem;
	float: left;
	border-radius: 4px;
	background-color: #fff;
}

.mobile-search-wrap {
	margin-top: 1px;
	margin-bottom: 1px;
	padding: 10px;
	background-color: rgba(57, 68, 78, 0.35);
	box-shadow: 0 -1px 0 0 hsla(0, 0%, 100%, 0.15), 0 1px 0 0 hsla(0, 0%, 100%, 0.15);
}

.mobile-search-input {
	width: 79%;
	height: 3rem;
	margin-right: 1%;
	margin-bottom: 0px;
	padding: 4px 4px 4px 10px;
	float: left;
	border: 1px solid #fff;
	border-radius: 4px;
	font-size: 0.75rem;
}

.mobile-search-wrap-container {
	display: none;
	overflow: hidden;
}

.footer-signoff-links-wrap {
	margin-top: 1rem;
	float: left;
}

.footer-signoff-link {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	border-bottom: 1px dotted rgba(94, 15, 15, 0.47);
	color: #d32329;
	font-size: 0.75rem;
	text-decoration: none;
}

.footer-signoff-link:hover {
	border-bottom: 1px dotted rgba(94, 15, 15, 0.47);
	color: #610609;
}

.inside-page-header {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	height: 10rem;
	background-color: transparent;
	background-image: none;
	background-position: 0px 0px;
	box-shadow: none;
}

.slider-contents-dark-wrapper {
	max-width: 66%;
	min-width: 50%;
	padding: 1.25em 2em 1.25em 1.25em;
	border-top: 2px solid #d32329;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(32, 31, 31, 0.65)), to(rgba(32, 31, 31, 0.65)));
	background-image: linear-gradient(180deg, rgba(32, 31, 31, 0.65), rgba(32, 31, 31, 0.65));
	background-position: 0px 0px;
	color: hsla(0, 0%, 100%, 0.9);
	font-size: 1.25rem;
	line-height: 1.25em;
}

.nav-dropdown-list-inner-wrap {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	border-top: 2px solid #09b988;
}

.inside-content-wrap.nested-middle {
	margin-right: 10px;
}

.inside-page-heading-elements-wrap.no-sidebars {
	display: block;
	max-width: 78%;
	margin-right: auto;
	margin-left: auto;
}

.sticky-cart-estimate-shipping-form-wrap {
	display: block;
	margin-top: 5px;
	margin-bottom: 0px;
}

.sticky-cart-estimated-shipping-figure {
	display: none;
	margin-bottom: 0.25em;
}

.sticky-cart-close-image {
	position: relative;
	display: none;
	width: 0.75rem;
	height: 1.5rem;
	margin-right: auto;
	margin-left: auto;
}

.sticky-cart-info-reveal {
	display: none;
	overflow: hidden;
}

.sticky-cart-open-image {
	position: relative;
	display: block;
	width: 0.75rem;
	height: 1.5rem;
	margin-right: auto;
	margin-left: auto;
}

.sticky-cart-header-checkout {
	display: inline-block;
	margin-top: -2px;
	margin-bottom: -2px;
	padding-top: 0.125rem;
	padding-right: 1.75rem;
	padding-left: 1rem;
	float: right;
	border-radius: 3px;
	background-color: #1b8354;
	background-image: url('/20170713122722/assets/images/icon_arrow2_right_wht.svg');
	background-position: 90% 50%;
	background-size: 10px 10px;
	background-repeat: no-repeat;
	font-family: Rajdhani, sans-serif;
	color: #fff;
	line-height: 1.75rem;
	font-weight: 600;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.sticky-cart-header-checkout:hover {
	background-color: #206bac;
	color: #fff;
}

.sticky-cart-open-text {
	width: 62px;
	padding-top: 0.125rem;
	padding-right: 0.25rem;
	padding-left: 0.5rem;
	float: left;
	font-family: Rajdhani, sans-serif;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.375rem;
	font-weight: 600;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.sticky-cart-open-text:hover {
	color: #24b273;
}

.sticky-cart-close-text {
	display: none;
	width: 62px;
	padding-top: 0.125rem;
	padding-right: 0.25rem;
	padding-left: 0.5rem;
	float: left;
	font-family: Rajdhani, sans-serif;
	color: #8f9aa7;
	font-size: 0.875rem;
	line-height: 1.375rem;
	font-weight: 600;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.sticky-cart-close-text:hover {
	color: #24b273;
}

.new-dropdown-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	border-right: 1px solid hsla(0, 0%, 100%, 0.25);
}

.new-dropdown-wrap:hover {
	background-color: rgba(52, 60, 71, 0.55);
}

.new-dropdown-toggle {
	position: relative;
	display: inline-block;
	flex-grow: 1;
	padding: 0px;
	transition: all 100ms ease;
	color: rgba(0, 0, 0, 0.35);
}

.new-dropdown-toggle.w--open {
	background: rgba(52, 60, 71, 0.55);
	color: hsla(0, 0%, 100%, 0.35);
}

.new-dropdown-toggle.w--open:hover {
	color: hsla(0, 0%, 100%, 0.65);
}

.new-dropdown-arrow {
	margin-right: 1.5rem;
}

.new-mega-menu-dropdown-toggle {
	display: block;
	width: 100%;
	padding: 0px;
	-webkit-transition: all 100ms ease;
	transition: all 100ms ease;
	color: #547197;
	font-size: 0.75em;
}

.new-mega-menu-dropdown-toggle:hover {
	background-color: #197b4f;
	color: #fff;
}

.new-mega-menu-dropdown-toggle.w--open {
	background: #2474b3;
	color: hsla(0, 0%, 100%, 0.7);
}

.new-mega-menu-dropdown-toggle.w--open:hover {
	background-color: #1a7f52;
	color: #fff;
}

.new-mega-menu-toggle-arrow {
	margin-right: 10px;
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.sticky-cart-shipping-estimate-reveal {
	position: relative;
	overflow: hidden;
}

.sticky-cart-estimate-shipping-text {
	margin-top: 0.25em;
	margin-bottom: 0.25em;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.slideshow-caption-text {
	color: #fff;
	font-family: inherit;
}

.footer-text {
	color: #5c5c5c;
	font-family: inherit;
}

html.w-mod-js *[data-ix="sticky-cart-initial-appearance-hide"] {
	display: none;
}

.div-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
}

.div-block-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 250px;
	padding-bottom: 20px;
	padding-left: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.w-nav:before,
.w-nav:after {
	content: " ";
	display: table;
	grid-column-start: 1;
	grid-row-start: 1;
	grid-column-end: 2;
	grid-row-end: 2;
}

@media (max-width: 991px) {
	.secondary-header-link-text {
		color: #fff;
	}

	.div-block-2 {
		width: 200px;
		padding-bottom: 25px;
	}

	.nav-logo-image {
		width: 190px;
	}

	.nav-logo-block {
		position: relative;
		display: inline-block;
		float: none;
	}

	.div-block {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.header-content-wrap {
		padding-right: 80px;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.header-container {
		display: block;
		width: 100%;
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		padding-right: 0em;
		padding-left: 0em;
	}

	.nav-section {
		background-color: #d32329;
		background-image: none;
		background-position: 0px 0px;
	}

	h1 {
		font-size: 2.125em;
	}

	.button {
		font-size: 1em;
	}

	.link.footer-list-link {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		padding-left: 5px;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
		line-height: 1rem;
		text-align: left;
	}

	.nav-menu {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-top: 0px;
		padding-bottom: 50vh;
		background-color: #eef5fc;
	}

	.nav-link {
		width: 100%;
		margin-right: 0px;
		padding-right: 0.875rem;
		padding-left: 0.875rem;
		float: none;
		border-bottom: 1px solid rgba(140, 164, 189, 0.35);
		box-shadow: inset 3px 0 0 0 transparent;
		color: #0b4b86;
		font-size: 1.25rem;
		text-align: left;
		text-transform: none;
	}

	.nav-link:hover {
		background-color: transparent;
		box-shadow: inset 3px 0 0 0 #0c8563;
		color: #0c8563;
	}

	.nav-link.w--current {
		background-color: #eef5fc;
		box-shadow: inset 3px 0 0 0 #0c8563;
		-webkit-transition: color 100ms ease;
		transition: color 100ms ease;
		color: #0b7557;
	}

	.nav-link.dropdown-toggle {
		width: 20%;
		float: right;
		border-bottom-style: none;
	}

	.nav-link.dropdown-toggle.two-part-button-toggle {
		position: relative;
		z-index: 1;
		width: 20%;
		padding-right: 0.25rem;
		padding-left: 0rem;
		float: right;
		border-left: 1px solid rgba(140, 164, 189, 0.35);
		border-right-style: none;
		background-color: #dbe1e9;
		text-align: right;
	}

	.nav-link.dropdown-toggle.two-part-button-toggle:hover {
		box-shadow: none;
	}

	.nav-link.dropdown-toggle.two-part-button-toggle.w--open {
		background: #09b988;
	}

	.nav-link.nav-two-part-button-link {
		z-index: 1;
		display: block;
		width: 80%;
		padding-right: 0.875rem;
		padding-left: 0.875rem;
		float: left;
		border-right-style: none;
		border-right-width: 0px;
		border-bottom-style: none;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
		color: #0b4b86;
		text-align: left;
	}

	.nav-link.nav-two-part-button-link:hover {
		box-shadow: inset 3px 0 0 0 #0c8563;
		color: #0c8563;
	}

	.nav-dropdown.two-part-button {
		position: relative;
		right: 0px;
		z-index: 0;
		width: 100%;
		float: none;
		text-align: left;
	}

	.nav-menu-button {
		width: 4.5rem;
		height: 4rem;
		padding: 1rem 0.25rem;
		float: left;
		box-shadow: 1px 0 0 0 hsla(0, 0%, 100%, 0.2);
		color: #fff;
		font-size: 2rem;
		text-align: center;
	}

	.nav-menu-button.w--open {
		background: #0c8563;
	}

	.nav-dropdown-list {
		max-width: 100%;
		min-width: 100%;
		margin-bottom: 2px;
		padding-top: 0rem;
		padding-bottom: 0rem;
		float: left;
		border-top: 1px solid rgba(140, 164, 189, 0.35);
		background-color: rgba(140, 164, 189, 0.15);
		background-image: none;
		box-shadow: inset 0 1px 0 0 hsla(0, 0%, 100%, 0.15), 0 2px 0 0 hsla(0, 0%, 100%, 0.15);
	}

	.nav-dropdown-link {
		padding: 1rem 0.875rem;
		color: #3f678a;
		font-size: 1.25rem;
	}

	.nav-dropdown-link:hover {
		background-color: transparent;
		color: #3f678a;
		text-decoration: none;
	}

	.nav-two-part-button {
		position: relative;
		display: block;
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		clear: both;
		border-bottom: 1px solid rgba(140, 164, 189, 0.35);
	}

	.two-part-button-arrow {
		display: block;
		margin-right: auto;
		margin-left: auto;
		float: none;
		background-image: url('/20170713122722/assets/images/icon_arrow2_right_ISU_blue.svg');
		background-size: 288px 1rem;
		opacity: 0.5;
	}

	.form-radio-button-field {
		margin-bottom: 0.5rem;
	}

	.slider {
		max-width: 100%;
	}

	.slider-arrow {
		left: auto;
		top: auto;
		right: 0px;
		bottom: 0px;
		max-height: 40px;
		max-width: 40px;
		min-height: 40px;
		color: hsla(0, 0%, 100%, 0.75);
		font-size: 1.25rem;
	}

	.slider-arrow.right-arrow {
		right: 0px;
		bottom: 41px;
	}

	.slider-indicators {
		height: 46px;
		padding-top: 17px;
		padding-right: 50px;
		padding-left: 50px;
	}

	.section.footer-section {
		text-align: center;
	}

	.section.off-white-section.inside-page-section {
		padding-top: 2.5rem;
	}

	.row.cb-row-2 {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.column.zeroed-column {
		padding-bottom: 1rem;
	}

	.breadcrumbs-list {
		margin-top: -2.75rem;
		margin-bottom: 2rem;
		padding-right: 0px;
		padding-left: 0px;
	}

	.inside-page-nested-content-column {
		margin-bottom: 2rem;
		padding-bottom: 2rem;
	}

	.inside-page-sidebar-column-section-wrap {
		margin-left: 0px;
	}

	.content-box-wrap {
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.content-box-image-link {
		width: 33%;
		height: 200px;
		margin-right: 2%;
		margin-bottom: 0rem;
		float: left;
		border-left: 3px solid #d32329;
		border-top-style: none;
	}

	.column-content-wrap {
		margin-right: 10px;
		margin-left: 10px;
	}

	.footer-signoff-row {
		text-align: center;
	}

	.footer-signoff-text {
		text-align: center;
	}

	.content-box-text-wrap {
		width: 65%;
		padding-bottom: 0.75rem;
		padding-left: 0.75rem;
		float: right;
	}

	.footer-social-icons-wrap {
		display: inline-block;
		width: auto;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.footer-social-icon-link {
		margin-right: 0.25rem;
		margin-left: 0.25rem;
	}

	.inside-page-intro.no-top-margin {
		margin-top: 0rem;
	}

	.footer-column-title {
		background-position: 50% 0px;
	}

	.slider-arrow-icon {
		top: auto;
		max-height: 40px;
		max-width: 40px;
		min-height: 40px;
		min-width: 40px;
		font-size: 1.25rem;
		line-height: 40px;
	}

	.nav-menu-wrap {
		margin-top: 0rem;
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.header-content-right-wrap {
		position: absolute;
		left: 0px;
		top: 0px;
		display: block;
		width: 100%;
		padding-top: 1.5rem;
		padding-right: 1.5rem;
		padding-left: 1rem;
		float: none;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.header-phone-wrap {
		font-size: 1.75rem;
	}

	.secondary-header-link-wrap {
		max-height: 4rem;
		margin-top: 1px;
		margin-left: 1px;
		padding: 1.25rem;
		float: right;
		clear: none;
		font-size: 1rem;
		line-height: 1.375rem;
		background-color: transparent;
	}

	.secondary-header-link-text.cart-item-label {
		font-size: 0.875rem;
	}

	.secondary-header-link-icon {
		width: 1.2rem;
		height: auto;
		margin-top: 0rem;
		margin-right: 0.5rem;
		opacity: 0.35;
	}

	.secondary-header-link-icon.search-icon {
		margin-right: 0rem;
	}

	.secondary-header-link-list-wrap {
		float: none;
		clear: none;
	}

	.footer-top-border {
		padding-top: 1.5rem;
		padding-bottom: 1rem;
	}

	.home-solutions-list {
		width: 100%;
		margin-right: 0rem;
		padding-right: 0rem;
		float: left;
		border-bottom-style: none;
	}

	.nav-container {
		max-width: 100%;
		padding-right: 0.5rem;
	}

	.home-solutions-list-item-link {
		padding-top: 1rem;
		padding-bottom: 0.875rem;
	}

	.slider-overlay-title {
		font-size: 2.25rem;
	}

	.wide-wrapper.white-wrapper {
		padding: 1rem 5px 0rem;
	}

	.wide-wrapper.white-wrapper.inside-page-wrapper {
		min-height: auto;
		margin-right: 5px;
		margin-left: 5px;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.clients-slider-slide {
		width: 25%;
	}

	.upcoming-events {
		width: 61%;
		padding-left: 1.75rem;
		float: right;
		border-right: 0px none #000;
		border-left: 1px solid rgba(111, 124, 136, 0.15);
	}

	.content-box-view-more-block.row-2-learn-more {
		position: static;
		margin-left: 0px;
	}

	.content-box-title {
		margin-top: 0em;
	}

	.home-solutions-content-wrap {
		width: 35%;
		padding-bottom: 0rem;
		float: left;
		border-right-style: none;
	}

	.clients-slider-cbox-wrap {
		margin-bottom: 2rem;
	}

	.home-column-right {
		width: 100%;
		margin-top: 1rem;
		padding-top: 1.5rem;
		padding-left: 0rem;
		border-top: 1px solid rgba(111, 124, 136, 0.15);
	}

	.home-column-left {
		width: 100%;
		padding-right: 0rem;
		border-right: 0px none transparent;
	}

	.content-box-alt-color-row {
		padding-right: 0px;
		padding-left: 0px;
	}

	.footer-address-split {
		float: left;
		text-align: left;
	}

	.slider-contents-inner-wrapper {
		left: 0px;
		right: 0px;
		bottom: 0px;
		padding-right: 41px;
	}

	.mobile-search-wrap-container {
		display: none;
	}

	.slider-contents-dark-wrapper {
		max-width: 100%;
		padding: 1em 20px 20px;
		font-size: 1.125rem;
	}

	.inside-content-wrap.nested-middle {
		margin-right: 0px;
	}

	.inside-content-wrap.side-padding {
		padding-right: 5px;
	}

	.inside-page-heading-elements-wrap.no-sidebars {
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.div-block-2 {
		padding-bottom: 20px;
	}

	.nav-logo-block {
		margin-bottom: 0.25rem;
		padding-left: 0px;
	}

	.header-content-wrap {
		padding-right: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	h1 {
		font-size: 1.875em;
	}

	h2 {
		font-size: 1.625em;
	}

	.button {
		font-size: 0.9em;
	}

	.link.footer-list-link {
		text-align: center;
	}

	.link.breadcrumb-link.mobile-parent-indicator {
		padding-top: 0.125rem;
		padding-left: 0.875rem;
		background-image: url('/20170713122722/assets/images/icon_arrow2_left_bluegray.svg');
		background-position: 0px 50%;
		background-size: 10px 10px;
		background-repeat: no-repeat;
	}

	.nav-link.dropdown-toggle.two-part-button-toggle {
		background: #dbe1e9;
	}

	.nav-menu-button {
		width: 4rem;
		height: 3.5rem;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}

	.slider-arrow {
		top: auto;
	}

	.slider-indicators {
		height: 41px;
		padding-top: 12px;
		padding-right: 45px;
		padding-left: 45px;
		font-size: 0.375rem;
	}

	.container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.container.inside-container {
		padding-right: 5px;
		padding-left: 5px;
	}

	.row {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.column {
		padding-bottom: 1rem;
	}

	.breadcrumbs-list {
		margin-top: -2.875rem;
	}

	.breadcrumbs-list-item {
		display: none;
	}

	.breadcrumbs-list-item.mobile-parent-item {
		display: inline-block;
	}

	.breadcrumbs-list-divider {
		display: none;
	}

	.content-box-wrap {
		margin-right: 15px;
		margin-left: 15px;
	}

	.content-box-image-link {
		width: 100%;
		height: 220px;
		float: none;
		border-top-style: solid;
		border-left-style: none;
	}

	.content-box-text {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.column-content-wrap {
		margin-top: 10px;
	}

	.footer-signoff-row {
		text-align: center;
	}

	.footer-signoff-text {
		display: block;
		float: none;
	}

	.content-box-text-wrap {
		width: 100%;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		float: none;
	}

	.inside-page-intro {
		font-size: 1.125em;
	}

	.slider-arrow-icon {
		top: auto;
		font-size: 1rem;
	}

	.header-content-right-wrap {
		position: relative;
		padding-top: 0rem;
	}

	.header-phone-wrap {
		display: inline-block;
		margin-right: 1rem;
		margin-left: 1rem;
		float: none;
		font-size: 1.375rem;
		line-height: 1.75rem;
	}

	.header-phone-icon {
		width: 1.75rem;
		height: 1.75rem;
		padding: 4px;
	}

	.secondary-header-link-wrap {
		max-height: 3.5rem;
		padding: 1rem 0.875rem;
		font-size: 0.8rem;
	}

	.secondary-header-link-list-wrap {
		margin-bottom: 0.25rem;
	}

	.footer-top-border {
		padding-top: 1rem;
		padding-bottom: 0.75rem;
	}

	.home-solutions-list-item-link {
		padding-top: 0.75rem;
		padding-bottom: 0.625rem;
	}

	.home-solutions-list-item {
		width: 48%;
		margin-right: 2%;
		float: left;
	}

	.slider-slide-image-block {
		min-height: 300px;
	}

	.slider-overlay-title {
		font-size: 1.875rem;
		line-height: 2rem;
	}

	.wide-wrapper.white-wrapper.inside-page-wrapper {
		padding-right: 0px;
		padding-left: 0px;
	}

	.wide-wrapper.light-overlay-wrapper {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.wide-wrapper.light-overlay-wrapper.inside-page-header-overlay {
		padding-top: 0.625rem;
		padding-bottom: 0.75rem;
	}

	.clients-slider-slide {
		width: 33.33%;
	}

	.home-event-wrap {
		width: 100%;
		border-bottom: 1px solid rgba(111, 124, 136, 0.15);
	}

	.upcoming-events {
		width: 98%;
		padding-bottom: 1.5rem;
		padding-left: 0rem;
		float: left;
		border-left: 1px none #000;
	}

	.home-solutions-content-wrap {
		width: 100%;
		margin-bottom: 1.5rem;
		padding-bottom: 0.5rem;
		float: left;
		border-bottom: 1px solid rgba(111, 124, 136, 0.15);
	}

	.clients-slider-cbox-wrap {
		margin-right: 10px;
		margin-left: 10px;
	}

	.home-column-right {
		margin-top: 0.5rem;
	}

	.footer-address-split {
		width: 50%;
	}

	.footer-signoff-links-wrap {
		float: none;
		clear: both;
	}

	.footer-signoff-link {
		margin-right: 1rem;
		margin-left: 1rem;
	}

	.slider-contents-dark-wrapper {
		font-size: 1rem;
	}

	.inside-content-wrap.mobile-side-padding {
		padding-right: 10px;
		padding-left: 10px;
	}

	.inside-page-heading-elements-wrap {
		margin-right: 10px;
		margin-left: 10px;
	}

	.inside-page-heading-elements-wrap.no-sidebars {
		margin-right: 10px;
		margin-left: 10px;
	}

	.sticky-cart-open-text {
		width: 58px;
		padding-left: 0.375rem;
	}

	.sticky-cart-close-text {
		width: 58px;
		padding-left: 0.375rem;
	}

	.footer-text {
		text-align: center;
	}

	.header-phone-wrap.desktop {
		display: none;
	}

	.header-phone-wrap.mobile {
		display: inline-block;
		max-height: 3.5rem;
		padding: 1rem 0.75rem;
		margin-right: 0px;
		margin-left: 0px;
		float: right;
		font-size: 0.9rem;
		line-height: 1.75rem;
		padding-top: 0.9rem;
		font-family: Rajdhani, sans-serif;
		font-weight: 600;
	}
}

@media (max-width: 479px) {
	.header-phone-wrap.mobile {
		padding-left: 0px;
		padding-right: 0px;
		line-height: 1.8rem;
		padding-top: 0.9rem;
		margin-top: 1px;
	}

	.secondary-header-link-wrap {
		margin-top: 1px;
	}

	.div-block-2 {
		width: 150px;
		padding-right: 10px;
		padding-bottom: 20px;
	}

	.nav-logo-image {
		width: 140px;
	}

	.nav-logo-block {
		display: inline-block;
		margin-left: 0rem;
		float: none;
	}

	.div-block {
		margin-bottom: 0px;
		padding-bottom: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	h1 {
		font-size: 1.625em;
	}

	h2 {
		font-size: 1.375em;
	}

	h3 {
		font-size: 1.2875em;
	}

	p {
		font-size: 0.9375rem;
	}

	.link.footer-list-link {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		font-size: 0.8125rem;
	}


	.section.footer-section {
		text-align: center;
	}

	.split-50 {
		width: 50%;
	}

	.content-box-image-link {
		height: 160px;
	}

	.column-content-wrap {
		margin-top: 0px;
	}

	.footer-list {
		margin-bottom: 0em;
		text-align: center;
	}

	.footer-social-icon-link {
		float: none;
	}

	.footer-column-title {
		font-size: 1.125rem;
	}

	.header-content-wrap {
		text-align: center;
	}

	.header-content-right-wrap {
		padding-right: 0.25rem;
		padding-left: 0rem;
	}

	.header-phone-wrap {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		font-size: 1rem;
	}

	.header-phone-icon {
		width: 1.5rem;
		margin-right: 0.125rem;
	}

	.secondary-header-link-wrap {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.secondary-header-link-text.cart-item-label {
		margin-left: 0.5rem;
	}

	.secondary-header-link-icon {
		margin-right: 0rem;
		font-size: 1rem;
	}

	.secondary-header-link-list-wrap {
		margin-top: 0rem;
		margin-bottom: 0rem;
	}

	.home-solutions-list-item {
		width: 100%;
		margin-right: 0%;
		float: none;
	}

	.slider-slide-image-block {
		min-height: 240px;
	}

	.slider-overlay-title {
		font-size: 1.375rem;
	}

	.wide-wrapper.light-overlay-wrapper.footer-signoff {
		padding-bottom: 1rem;
	}

	.clients-slider-slide {
		width: 50%;
	}

	.upcoming-events {
		width: 100%;
	}

	.content-box-title {
		margin-top: 0.5em;
	}

	.footer-address-split {
		width: 100%;
	}

	.footer-signoff-links-wrap {
		margin-top: 0rem;
	}

	.footer-signoff-link {
		margin-top: 1rem;
	}

	.slider-contents-dark-wrapper {
		max-width: 100%;
		font-size: 0.9375rem;
	}

	.inside-content-wrap.mobile-side-padding {
		padding-right: 8px;
		padding-left: 8px;
	}

	.sticky-cart-header-checkout {
		padding-right: 1.375rem;
		padding-left: 0.625rem;
		background-size: 9px 9px;
		font-size: 0.875rem;
	}

	.sticky-cart-open-text {
		width: 52px;
		font-size: 0.75rem;
	}

	.sticky-cart-close-text {
		width: 52px;
		font-size: 0.75rem;
	}

	.column-2 {
		margin-bottom: 1rem;
	}

	.secondary-header-link-text {
		font-size: 0.8rem;
	}
}

@media (max-width: 450px) {
	.secondary-header-link-wrap {
		padding-bottom: 0px;
	}

	.header-phone-wrap.mobile {
		padding: 0px;
		margin-top: 0px;
		margin-right: 15px;
		width: 60%;
		text-align: right;
	}

	.header-phone-wrap.mobile * {
		float: none;
	}

	.nav-menu-button {
		padding-top: 1.4rem;
		padding-bottom: 3rem;
	}
}


/***************** Webflow Styles *******************/
/****************************************************/
html {
	font-size: 1rem;
}

body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

.w-dropdown-link {
	white-space: normal;
}

.nav-dropdown-link {
	white-space: normal;
}

.navbar-wrap a[href^=tel] {
	color: #fff;
	text-decoration: none;
	border-bottom: 0px solid transparent;
}

.footer-call-section a[href^=tel] {
	color: #ffffff;
	text-decoration: none;
	border-bottom: 0px solid transparent;
}

/****************** Tablesaw ***********************/
/****************************************************/
table.styled.tablesaw th,
table.styled.tablesaw td {
	border-left: 0;
	border-top: 0;
}


@media (max-width: 50em) {
	table.styled.tablesaw th,
	table.styled.tablesaw td {
		border-right: 0;
	}
}

@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printHeader,
#printFooter {
	display: none;
}

/****************** Header ***********************/
/*************************************************/
.secondary-header-link-text.mobile {
	display: none;
}

.secondary-header-link-text.desktop {
	display: block;
}

.desktop-search-button {
	text-indent: -999px;
	border: 0px;
}

.secondary-header-link-text.phone {
	font-size: 14.5px;
}

@media ( min-width: 991px ) {
	.search-form {
		display: block !important;
		opacity: 1;
	}
}

@media ( max-width: 991px ) {
	.secondary-header-link-list-wrap.phone {
		font-size: 1.75rem;
	}

	.secondary-header-link-list-wrap.phone .secondary-header-link-icon {
		width: 2.25rem;
		height: 2.25rem;
		margin-right: 0.25rem;
		padding: 6px;
		float: left;
		opacity: 0.35;
	}

	.secondary-header-link-list-wrap.phone .secondary-header-link-wrap {
		padding: 0;
		float: left;
		margin-right: 0.5rem;
	}

	.secondary-header-link-list-wrap.phone .secondary-header-link-text.mobile {
		margin-top: 7px;
	}

	.secondary-header-link-text.mobile {
		display: block;
		color: #fff;
		text-decoration: none;
		font-size: 1.75rem;
		border-bottom: 0 solid transparent;
	}

	.secondary-header-link-text.desktop {
		display: none;
	}

	.mobile-search-wrap-container {
		box-shadow: 0 -1px 0 0 hsla(0, 0%, 100%, 0.15), 0 1px 0 0 hsla(0, 0%, 100%, 0.15);
		background: rgba(57, 68, 78, 0.35);
	}

	.search-form {
		opacity: 0;
		display: block !important;
		background-color: transparent;
		height: 0;
	}

	.search-form.active {
		opacity: 1;
		margin: 0px;
		border-radius: 0px;
		width: calc(100% - 20px);
		left: 10px;
		-webkit-transition: opacity 800ms ease;
		transition: opacity 800ms ease;
		position: absolute;
		margin-top: 135px;
		z-index: 100;
		height: auto;
	}

	.search-form input[type="text"].header-search-input {
		width: 79%;
		height: 3rem;
		margin-right: 1%;
		margin-bottom: 0px;
		padding: 4px 4px 4px 10px;
		float: left;
		border: 1px solid #fff;
		border-radius: 4px;
		font-size: 0.75rem;
		max-width: none;
		display: block;
	}

	.search-form .desktop-search-button {
		overflow: hidden;
		width: 20%;
		height: 3rem;
		margin-top: 0em;
		margin-bottom: 0em;
		padding-right: 0em;
		padding-left: 0em;
		float: right;
		background-image: url("/20170713122722/assets/images/search_wht.svg");
		background-position: 50% 50%;
		background-size: 1em 1em;
		background-repeat: no-repeat;
		color: transparent;
		line-height: 1.5em;
		border-radius: 4px;
		background-color: #0c8563;
		transition: background-color 200ms ease, color 200ms ease;
		font-family: Rajdhani, sans-serif;
		font-weight: 600;
		text-align: center;
		letter-spacing: 1px;
		text-decoration: none;
		text-transform: uppercase;
		opacity: 1;
		cursor: pointer;
	}

	.search-form .desktop-search-button:hover {
		background-color: rgba(37, 50, 66, 0.5);
		cursor: pointer;
	}

	.search-form .desktop-search-button {
		font-size: 1em;
	}
}


@media ( max-width: 767px ) {
	.search-form.active {
		margin-top: 71px;
	}

	.search-form .desktop-search-button {
		font-size: 0.9em;
	}

	.secondary-header-link-list-wrap.phone {
		display: none;
	}
}


@media ( max-width: 479px ) {
	.search-form.active {
		margin-top: 68px;
	}
}

/****************** Accesible menu ****************/
/*************************************************/
ul.new-nav-menu {
	margin: 0px;
	padding: 0px;
}

ul.new-nav-menu li.new-dropdown-toggle {
	margin-bottom: 0px;
	list-style-type: none;
	border-right: 1px solid hsla(0, 0%, 100%, 0.25);
}

ul.new-nav-menu li.new-dropdown-toggle .nav-link {
	position: relative;
	display: block;
	width: 100%;
	margin-right: 0px;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	float: none;
	box-shadow: none;
	text-align: center;
}


ul.new-nav-menu li.new-dropdown-toggle.hasChildren .nav-link {
	padding-right: 3rem;
}

ul.new-nav-menu li.new-dropdown-toggle.hasChildren .nav-link.active,
ul.new-nav-menu li.new-dropdown-toggle:hover .nav-link,
ul.new-nav-menu li.new-dropdown-toggle .nav-link.active-item {
	background-color: rgba(52, 60, 71, 0.55);
	color: #fff;
}

ul.new-nav-menu li.new-dropdown-toggle.hasChildren:hover:after {
	color: hsla(0, 0%, 100%, 0.65);
}

ul.new-nav-menu li.new-dropdown-toggle.hasChildren:after {
	content: "\e603";
	font-family: 'webflow-icons' !important;
	position: absolute;
	top: -8px;
	right: 0;
	bottom: 0;
	margin: auto;
	width: 1em;
	height: 1em;
	margin-right: 1.5rem;
}

ul.new-nav-menu li.new-dropdown-toggle .container-wrapper {
	display: inline-block;
	width: 100%;
	position: absolute;
	top: 100%;
}

ul.new-nav-menu li.new-dropdown-toggle .container-wrapper .nav-dropdown-list {
	display: block;
	margin: 0px;
	padding: 0px;
	list-style-type: none;
	max-height: 0px;
	overflow: hidden;
	height: auto;
	max-width: 230px;
	min-width: 100%;
	background-color: rgba(238, 245, 252, 0.9);
	box-shadow: inset 1px 0 0 0 rgba(140, 164, 189, 0.25), inset 0 -1px 0 0 rgba(140, 164, 189, 0.25), inset -1px 0 0 0 rgba(140, 164, 189, 0.25);
	-webkit-transition: all 100ms ease;
	transition: all 100ms ease;
}


ul.new-nav-menu li.new-dropdown-toggle.open .container-wrapper .nav-dropdown-list {
	-webkit-transition: max-height 200ms ease;
	transition: max-height 200ms ease;
	max-height: 500px;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	border-top: 2px solid #d32329;
}

ul.new-nav-menu li.new-dropdown-toggle .container-wrapper .nav-dropdown-list li {
	margin: 0px;
}

ul.new-nav-menu li.new-dropdown-toggle .container-wrapper .nav-dropdown-list li a {
	white-space: normal;
	margin-top: 1px;
	padding: 0.625rem 0.625rem 0.5rem;
	box-shadow: 0 -1px 0 0 rgba(118, 148, 179, 0.35);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Rajdhani, sans-serif;
	color: #414141;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 600;
	letter-spacing: 0.03em;
	text-decoration: none;
	display: inline-block;
	width: 100%;
	background-color: hsla(0, 0%, 100%, 0.29);
}

ul.new-nav-menu li.new-dropdown-toggle .container-wrapper .nav-dropdown-list li a:hover,
ul.new-nav-menu li.new-dropdown-toggle .container-wrapper .nav-dropdown-list li a.w--current,
ul.new-nav-menu li.new-dropdown-toggle .container-wrapper .nav-dropdown-list li a.active {
	background-color: #d32329;
	color: #fff;
}


/****************** Mobile navigation ****************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mm-wrapper_opened .nav-menu-button {
	background: #9e171c;
}

.mobile-navigation-menu {
	background: #fafafa;
}

.mobile-navigation-menu li:after {
	border-bottom: 0px;
}

.mobile-navigation-menu li a.nav-link {
	padding-right: 0.875rem;
	padding-left: 0.875rem;
	font-size: 1.25rem;
	text-align: left;
	text-transform: none;
	font-weight: 600;
	letter-spacing: 1px;
	font-family: Rajdhani, sans-serif;
	color: #414141;
	padding-top: 1rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid rgba(140, 164, 189, 0.35);
}

.mobile-navigation-menu li a.nav-link:hover {
	box-shadow: inset 3px 0 0 0 #610609;
	color: #d32329;
}

.mobile-navigation-menu li.active a.nav-link {
	background-color: rgba(211, 35, 41, 0.12);
	box-shadow: inset 3px 0 0 0 #610609;
	color: #d32329;
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
	background: rgba(211, 35, 41, 0.12);
	width: 80px;
	border-bottom: 1px solid rgba(140, 164, 189, 0.35);
}

.mm-menu .mm-listview .mm-btn_next:after {
	border-color: rgba(0, 0, 0, 0.3);
	right: 37px;
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + a,
.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + span {
	margin-right: 80px;
	width: calc(100% - 80px);
}

.mm-navbar {
	height: 50px;
	text-align: left;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -10px;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
	font-size: 1.25rem;
	text-align: left;
	text-transform: none;
	font-weight: 600;
	letter-spacing: 1px;
	font-family: Rajdhani, sans-serif;
	color: #414141;
	padding-top: 15px;
}

.mm-menu .mm-btn:before {
	left: 18px;
	top: 7px;
	border-color: #414141;
}

/********************** Homepage ********************/
/****************************************************/
h2.content-box-title {
	color: #d32329;
}

h2.content-box-title a:hover {
	color: #9e171c;
}

h2.content-box-title a {
	text-decoration: none;
	color: inherit;
	border-bottom: 0px solid transparent;
}

.homeboxes h3 {
	color: #9e171c;
}

.homeboxes h1 a,
.homeboxes h2:not(.content-box-title) a,
.homeboxes h3 a,
.homeboxes h4 a,
.homeboxes h5 a,
.homeboxes h6 a {
	color: #d32329;
}

.homeboxes h1 a:hover,
.homeboxes h2:not(.content-box-title) a:hover,
.homeboxes h3 a:hover,
.homeboxes h4 a:hover,
.homeboxes h5 a:hover,
.homeboxes h6 a:hover {
	color: #610609;
}

.home-solutions-content-wrap ul {
	border-bottom: 1px solid rgba(111, 124, 136, 0.15);
	padding-left: 0;
	list-style: none;
	margin-top: 0.5em;
	margin-bottom: 0.75em;
}

.home-solutions-content-wrap ul li {
	margin-bottom: 0em;
	border-top: 1px solid rgba(111, 124, 136, 0.15);
}

.home-solutions-content-wrap ul li a {
	display: block;
	padding-top: 0.75rem;
	padding-bottom: 0.625rem;
	border-bottom: 0px none transparent;
	background-image: url("/20170713122722/assets/images/icon_arrow3_right_cblgray.svg");
	background-position: 97% 50%;
	background-size: 10px 10px;
	background-repeat: no-repeat;
	font-family: Rajdhani, sans-serif;
	font-size: 1.125rem;
	line-height: 1.125rem;
	font-weight: 600;
	padding-left: 0.375rem;
	text-decoration: none;
	color: #414141;
	border-bottom: 1px solid transparent;
}

.home-solutions-content-wrap ul li a:hover {
	color: #d32329;
}

@media (max-width: 991px) {
	.home-solutions-content-wrap ul {
		width: 100%;
		margin-right: 0rem;
		padding-right: 0rem;
		float: left;
		border-bottom-style: none;
	}

	.home-solutions-content-wrap ul li a {
		padding-top: 1rem;
		padding-bottom: 0.875rem;
	}
}

@media (max-width: 767px) {
	.content-box-text p {
		font-size: inherit;
		line-height: inherit;
	}

	.home-solutions-content-wrap ul li {
		width: 48%;
		margin-right: 2%;
		float: left;
	}

	.home-solutions-content-wrap ul li a {
		padding-top: 0.75rem;
		padding-bottom: 0.625rem;
	}
}

@media (max-width: 479px) {
	.home-solutions-content-wrap ul li {
		width: 100%;
		margin-right: 0%;
		float: none;
	}
}

/******************** Slideshow *********************/
/****************************************************/
.slideshow-caption-text p {
	font-size: inherit;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
	margin-bottom: 0.55rem;
}

.section.slider-section {
	min-height: 390px;
}

@media (max-width: 991px) {
	.section.slider-section {
		height: 40vh;
		min-height: 320px;
		min-width: 320px;
	}
}

@media (max-width: 767px) {
	.slider-contents-dark-wrapper .button {
		font-size: 0.9em;
	}

	.section.slider-section {
		height: 45vh;
	}
}

@media (max-width: 479px) {
	.slider-contents-dark-wrapper.hide-on-mobile {
		display: none;
	}

	.section.slider-section {
		height: 45vh;
	}
}

/******************** Inside page ********************/
/*****************************************************/
img {
	height: auto;
	max-width: 100%;
}

td img {
	max-width: none;
}

.left-nav-list-item-link.active:not(.nested-left-nav-link) {
	background-color: #d32329;
	color: #fff;
}

.left-nav-list-item-link.nested-left-nav-link.active {
	background-color: transparent;
	background-image: url("/20170713122722/assets/images/Arrow4_right_blk_50.svg");
	background-size: 5px 9px;
	color: #d32329;
}

.breadcrumbs-list .breadcrumbs-list-item:last-child .breadcrumbs-list-divider {
	display: none;
}

.only-right .inside-page-content-column {
	padding-right: 0px;
	padding-left: 0px;
}

.only-right .inside-page-nested-content-column {
	padding-right: 10px;
	padding-left: 10px;
}

.only-right .inside-content-wrap.nested-middle {
	margin: 0px;
}

.only-right .inside-page-nested-right-column {
	padding-right: 10px;
	padding-left: 10px;
}

@media (max-width: 991px) {
	.no-columns .inside-page-content-column {
		padding-left: 0px;
		padding-right: 0px;
	}

	.no-columns .inside-page-row {
		margin-right: 0px;
		margin-left: 0px;
	}
}

@media (max-width: 767px) {
	.no-columns .inside-page-content-column {
		padding-right: 10px;
		padding-left: 10px;
	}

	.no-columns .breadcrumbs-list {
		margin-left: 0px;
	}

	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/*************** Right Sidebar ********************/
/*************************************************/
.inside-page-nested-right-column .box {
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
	margin-left: 15px;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-image: url("/20170713122722/assets/images/bluegray_160x6.png");
	background-position: 0px 0px;
	background-size: 20px 2px;
	background-repeat: no-repeat;
	font-size: 0.875rem;
	line-height: 1.375em;
	display: inline-block;
	width: 100%;
}

.inside-page-nested-right-column .box h4,
.inside-page-nested-right-column .box h3 {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	color: #9e171c;
	font-size: 1.5em;
	line-height: 1em;
	font-family: Rajdhani, sans-serif;
	font-weight: 600;
}

.inside-page-nested-right-column .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.inside-page-nested-right-column .box .contentbox_item_image {
	width: 24px;
}

.inside-page-nested-right-column .box.documents td:first-child {
	padding-right: 7px;
}

.inside-page-nested-right-column .box.tools td:first-child img {
	margin-right: 5px;
}

@media (max-width: 991px) {
	.inside-page-nested-right-column .box {
		margin-left: 0em;
		padding-right: 0px;
		padding-left: 0px;
	}
}

/********************** Footer ***********************/
/*****************************************************/
.footer-text a {
	color: #5c5c5c;
	text-decoration: none;
	border-bottom: 0px solid transparent;
}

.footer-list {
	-moz-column-count: 2;
	-webkit-column-count: 2;
	column-count: 2;
}

.footer-list-item {
	display: inline-block;
	width: 100%;
	border: 0px;
}

.footer-list .footer-list-item a {
	border-bottom: 1px solid rgba(148, 164, 180, 0.35);
	border-top: 0px solid transparent;
}

.footer-list .footer-list-item a.active {
	color: #610609;
}

.footer-list .footer-list-item:first-child a,
.footer-list .footer-list-item.add-border a {
	border-top: 1px solid rgba(148, 164, 180, 0.35);
}


/****************** Inside Page Styles ***************/
/*****************************************************/
body a {
	color: #d32329;
}

body a:hover {
	color: #610609;
}

.inside-page-content-column ul {
	margin-top: 0.25rem;
	margin-bottom: 0.75rem;
	padding-left: 20px;
}

/* buttons */
.button,
.primary,
button[type="submit"],
.cms_form_button.primary,
.form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.button {
	padding: 0.5rem 1.25rem;
	border-radius: 4px;
	background-color: #d32329;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	font-family: Rajdhani, sans-serif;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
	border: 0px;
	cursor: pointer;
}

.button:hover,
.primary:hover,
button[type="submit"]:hover,
.cms_form_button.primary:hover,
.form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.button:hover {
	background-color: #9e171c;
	color: #fff;
	cursor: pointer;
}

.secondary,
button[type="submit"].secondary,
.cms_form_button.secondary,
.form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary {
	padding: 0.5rem 1.25rem;
	border-radius: 4px;
	background-color: #475461;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	font-family: Rajdhani, sans-serif;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
	border: 0px;
	cursor: pointer;
}

.secondary:hover,
button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
.form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover {
	background-color: #2c353d;
	color: #fff;
	cursor: pointer;
}

.tertiary,
button[type="submit"].tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	padding: 0.5rem 1.25rem;
	border-radius: 4px;
	background-color: #d4dae0;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	font-family: Rajdhani, sans-serif;
	color: #475461;
	font-size: 1.125rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
	border: 0px;
	cursor: pointer;
}

.tertiary:hover,
button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	background-color: #caccce;
	color: #475461;
	cursor: pointer;
}

/* messages */
#message.success,
#polls .session_message.success {
	background-color: rgba(17, 185, 30, 0.25);
	padding: 20px;
	margin-bottom: 20px;
	margin-top: 20px;
	color: #000000;
	font-weight: 500;
}

#message.error,
#polls .session_message.error {
	background-color: rgba(253, 54, 54, 0.15);
	text-align: left;
	padding: 20px;
	margin-bottom: 20px;
	margin-top: 20px;
	color: #000000;
}

#message.success *,
#message.error *,
#polls .session_message.success p,
#polls .session_message.error p {
	color: #000000;
	font-size: 1.0625rem;
	line-height: 1.5em;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	background-color: white;
}

table.styled th,
table.styled td {
	border-right: 1px solid rgba(0, 0, 0, 0.1);
	vertical-align: top;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	text-align: left;
	font-weight: normal;
	font-size: 1.25em;
	line-height: 1.25em;
	padding: 0.625em 0.625rem;
	color: #ffffff;
	background-color: #d32329;
}

table.styled th {
	text-align: left;
	font-weight: normal;
	font-size: 1em;
	line-height: 1.25em;
	padding: 0.625em 0.625rem;
	color: #ffffff;
	background-color: #9e171c;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
}

table.styled th a:hover {
	color: #fff;
	text-decoration: underline;
}

table.styled td {
	text-align: left;
	font-weight: normal;
	font-size: 1em;
	line-height: 1.375em;
	padding: 0.375em 0.625rem;
	color: #333;
}

table.styled.striped tr:nth-child(even) {
	background: rgba(0, 0, 0, 0.1);
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="search"],
select,
textarea {
	max-width: 100%;
	padding: 0.39rem 0.75rem;
	border: 1px solid #d6d6d6;
	border-radius: 0px;
	font-size: 0.875rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	margin-bottom: 10px;
}

input[type="search"] {
	margin-bottom: 0px;
}

select {
	padding: 0.45rem 0.75rem;
}

textarea {
	resize: vertical;
}

@media (max-width: 991px) {
	input[type="text"],
	input[type="tel"],
	input[type="password"],
	input[type="email"],
	input[type="search"],
	select,
	textarea {
		width: 100%;
	}


	input[type="search"] {
		margin-bottom: 10px;
	}
}

/*blockquotes*/
blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	color: #414141;
	margin-top: 0;
}

/*horizontal ruler*/
hr:not(.fc-divider):not(.formBreak) {
	border-color: hsla(0, 0%, 45%, 0.11);
	border: 0px;
	color: hsla(0, 0%, 45%, 0.11);
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 2px solid;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
	color: #333 !important;
}

.pagination-wrapper span {
	color: #333 !important;
	font-size: inherit;
}

/*skip to main content WCAG link*/
.cms_form_button.primary.skip-to-main-content.sronly {
	padding: 0px;
}

.cms_form_button.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	box-shadow: 0 0 5px #000;
	z-index: 2000;
	padding: 0.5rem 1.25rem;
}


/***** Properties Module ****/
/***************************/

/* Content Box */
#properties-module.homepage_contentbox.search .layout-search .dropdown-title.secondary {
	font-size: 1.5rem;
	line-height: 1.8rem;
}

#properties-module.homepage_contentbox.search .layout-search .dropdown-item .dropdown-title i {
	font-size: 1.2rem;
}

#properties-module.homepage_contentbox.search .layout-search .search-item select {
	font-size: 1.5rem;
	line-height: 1.8rem;
	padding: 5px 7px !important;
	height: auto;
}

#properties-module.homepage_contentbox.search .layout-search .properties-blocks-search-button {
	font-size: 1.5rem;
	line-height: 1.8rem;
	margin-top: 10px;
}

#properties-module.homepage_contentbox.search .layout-search .properties-blocks-search-form {
	background-color: rgba(0, 0, 0, 0.45);
	border-radius: 5px;
}

@media (max-width: 991px) {
	#properties-module.homepage_contentbox.search .layout-search .dropdown-title.secondary,
	#properties-module.homepage_contentbox.search .layout-search .properties-blocks-search-button,
	#properties-module.homepage_contentbox.search .layout-search .search-item select {
		font-size: 1.1rem;
		line-height: 1.2rem;
	}

	#properties-module.homepage_contentbox.search .layout-search .dropdown-item .dropdown-title i {
		font-size: 0.8em;
	}

	#properties-module.homepage_contentbox.search .layout-search .properties-blocks-search-form .dropdown-item {
		padding: 5px 0;
	}
}

/* Listin Views (Map / Card ) */
.module-properties.controller-pages-properties.action-index .inside-page-left-column,
.module-properties.controller-pages-properties.action-index .inside-page-nested-right-column {
	display: none;
}

.module-properties.controller-pages-properties.action-index .inside-page-content-column,
.module-properties.controller-pages-properties.action-index .inside-container,
.module-properties.controller-pages-properties.action-index .inside-page-wrapper,
.module-properties.controller-pages-properties.action-index .inside-page-row,
.module-properties.controller-pages-properties.action-index .footer-top-border,
.module-properties.controller-pages-properties.action-index .footer-signoff,
.module-properties.controller-pages-properties.action-index .footer-wrap {
	width: 100%;
	max-width: none;
	margin-right: 0;
	margin-left: 0;
}

@media (min-width: 992px) {
	#properties-module.list.card .layout-columns.results #innerResults #resultList li {
		width: calc(25% - 10px);
	}

	#properties-module.list.card .layout-columns.results #innerResults #resultList li:nth-child(3n + 1) {
		margin-left: 5px;
	}

	#properties-module.list.card .layout-columns.results #innerResults #resultList li:nth-child(3n) {
		margin-right: 5px;
	}

	#properties-module.list.card .layout-columns.results #innerResults #resultList li:nth-child(4n + 1) {
		margin-left: 0px;
	}

	#properties-module.list.card .layout-columns.results #innerResults #resultList li:nth-child(4n) {
		margin-right: 0px;
	}

	#properties-module.list.map .layout-columns.results #innerResults #resultList li {
		width: calc(33% - 10px);
	}

	#properties-module.list.card .layout-columns.results #innerResults #resultList li .property-card-image {
		height: 200px;
	}
}
